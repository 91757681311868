<template>
  <div>
    <!-- 导航 -->
    <el-breadcrumb>
      <el-breadcrumb-item>
        <router-link to="./">首页</router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item>其他文章管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 主体内容 -->
    <div class="content">
      <el-form
        inline
        label-position="right"
        label-width="60px"
        size="small"
        class="query-form"
      >
        <el-form-item label="搜索">
          <el-input v-model="key" placeholder="请输入关键词"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="selList()">查询</el-button>
          <el-button
            type="success"
            @click="
              dialogVisible = true;
              form = { isEnable: true };
              cateList = [];
            "
            >新增</el-button
          >
        </el-form-item>
      </el-form>
      <!-- 
      
            
             
    


       -->
      <!-- 列表查询 -->
      <el-card class="box-card table">
        <div slot="header" class="clearfix">
          <span>其他文章管理</span>
        </div>

        <el-tabs v-model="query.type" @tab-click="selList()">
          <el-tab-pane
            :label="item.name"
            :name="item.id.toString()"
            v-for="item in typeList"
            :key="item.id"
          ></el-tab-pane>
        </el-tabs>
        <!-- 列表定义  C#程序中接口读取的数据在这里展示.  -->
        <el-table
          v-loading="loading"
          :data="tableData"
          ref="tableData"
          border
          stripe
          style="width: 100%"
          :tree-props="{ children: 'children' }"
        >
          <el-table-column prop="title" label="标题">
            <div slot-scope="scope">
              <a :href="scope.row.link" target="_blanck">
                {{ scope.row.title }}</a
              >
            </div>
          </el-table-column>
          <!-- 所属类型  范围 行  -->
          <el-table-column prop="sort" label="排序"></el-table-column>
          <el-table-column prop="typeName" label="所属类型"></el-table-column>
          <el-table-column prop="createTime" label="添加时间"></el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-tooltip content="编辑" placement="top">
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  @click="editClick(scope.row)"
                ></el-button>
              </el-tooltip>
              <el-tooltip content="删除" placement="top">
                <el-button
                  type="warning"
                  size="mini"
                  icon="el-icon-delete"
                  @click="delClick(scope.row)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页条 -->
        <div class="block" style="margin-top: 20px">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[10, 20, 30, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes,prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </el-card>
    </div>

    <!-- 操作对话框 -->
    <!--dialog 对话框
在保留当前页面状态的情况下，告知用户并承载相关操作。 -->
    <el-dialog
      :title="form.id > 0 ? '编辑链接' : '添加其他文章'"
      :visible.sync="dialogVisible"
      @opened="dialogOpened()"
    >
      <el-form
        :model="form"
        ref="form"
        label-width="100px"
        :status-icon="true"
        @submit.native.prevent="submitForm('form')"
      >
        <!-- 标题  -->
        <el-form-item
          label="标题"
          prop="title"
          :rules="[
            { required: true, message: '请输入标题' },
            { min: 2, max: 30, message: '标题最少2字，最大30字' },
          ]"
        >
          <el-input v-model="form.title" placeholder="请输入标题"></el-input>
        </el-form-item>

        <!-- 所属分类 -->
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="所属分类"
              prop="type"
              :rules="{ required: true, message: '不能为空' }"
            >

              <el-select
                v-model="form.type"
                placeholder="请选择类型"
                filterable
              >
                <el-option
                  v-for="item in typeList"
                  :label="item.name"
                  :value="item.id"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 代表图 -->
        <el-form-item label="代表图">
          <div
            id="uploadBtn1"
            class="uploader uploader-warning"
          ></div>
          <img
            id="relativeName"
            :src="domain + form.imgUrl"
            style="height: 40px; position: relative; left: 10px; top: 20px"
            v-if="form.imgUrl"
          />
          <div class="uploader-text">
            * 图片尺寸：推荐300px*210px
          </div>
        </el-form-item>

        <el-form-item  v-if="form.type == 1"
          label="链接地址"
          prop="link"
          :rules="[{ type: 'url', message: '请输入正确的url' }]"
        >
          <el-input v-model="form.link" placeholder="请输入url地址"></el-input>
        </el-form-item>
        <el-form-item label="简介" prop="summary">
          <el-input
            v-model="form.summary"
            placeholder="请输入简介内容"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="排序字段"
          prop="sort"
          :rules="[
            { required: false, message: '不能为空' },
            { type: 'number', message: '请填写数字' },
          ]"
        >
          <el-input
            v-model.number="form.sort"
            placeholder="请输入排序字段"
          ></el-input>
        </el-form-item>
           <!-- content 是富文本组件 -->
           <el-form-item label="其他文章" prop="content">
                        
          <div style="position: relative">
                            <vue-ueditor-wrap
              v-model="form.content"
              :config="editorConfig"
            ></vue-ueditor-wrap>
                          
          </div>

                      </el-form-item
        >

        <el-form-item>
          <el-button type="primary" :loading="submiting" native-type="submit"
            >提交</el-button
          >
          <el-button @click="dialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

 
<script>
import url from "@/plugins/urlHelper.js";
// 1 富文本组件导入
import VueUeditorWrap from "vue-ueditor-wrap";
export default {
  components: { VueUeditorWrap },//2 组件使用  
  data() {
    return {
      // 3 增加富文本 
      editorConfig: {
        initialFrameHeight: 500, //设置高度
        initialFrameWidth: "100%", //设置宽度
        UEDITOR_HOME_URL: url.getUEConfig(),
        serverUrl: url.getUE(),
      },   
      domain: url.getDomain(),
      key: "",
      query: { type: "1" }, //对象
      loading: false,
      currentPage4: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      form: {}, //表单对象
      dialogVisible: false,
      submiting: false,
      typeList: [],
      id: 0,
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage4 = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage4 = val;
      this.getList();
    },
    //筛选
    filterTag(filter) {
      var val = filter["isEnable"][0];
      this.form.isEnable = val;
      //刷新列表
      this.getList();
    },
    //排序
    sortChange(sortColumn) {
      this.form.sort = sortColumn.prop;
      this.form.order = sortColumn.order;
      //刷新列表
      this.getList();
    },
    // 查询列表 获取分页列表方法
    getList: function () {
      var _this = this;
      _this.loading = true;
      var params = _this.query;
      params.pageindex = _this.currentPage4;
      params.pagesize = _this.pageSize;
      params.key = _this.key;
      var link = url.getApplyRecord("GetList_ArticleOther");

      $.get(link, params, (data) => {
        _this.loading = false;
        _this.tableData = data.list;
        _this.total = data.total;
      });
    },
    //搜索查询
    selList: function () {
      this.currentPage4 = 1;
      this.getList();
    },
    //获取类型列表
    initData: function () {
      var _this = this;
      var link = url.getApplyRecord("GetType_OtherType");
      $.get(link, {}, (data) => {
        _this.typeList = data;
      });
    },

    //上传文件
    dialogOpened: function () {
      var _this = this;

      // 上传图片
      if ($("#uploadBtn1 input").length == 0) {
        $("#uploadBtn1").uploader({
          url: url.getUpload(),
          text: "选择图片",
          fileExts: "jpg;png",
          handleType: "0",
          maxSize: 1024 * 1024 * 1,
          onSuccess: function (data) {
            var src = url.getDomain() + data.relativeName;
            _this.$set(_this.form, "imgUrl", data.relativeName);
          },
        });
      }
    },

    //提交表单
    submitForm(formName) {
      var _this = this;
      var link = url.getApplyRecord("Save_ArticleOther"); //添加.修改 
      var info = this.form;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submiting = true;
          //提交后台添加
          //添加或者修改
          $.post(link, info, (res) => {
            _this.submiting = false;
            if (res.status) {
              _this.dialogVisible = false;
              _this.getList();
              _this.$message({
                type: "success",
                message: res.msg,
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    //编辑
    editClick: function (row) {
      //this.form = Object.assign({}, row);
      this.dialogVisible = true;
      var _this=this;
      var id=row.id;
      var link=url.getApplyRecord('getmodel_articleother');
      $.get(link,{id:id},res=>{
          res.content=res.content||'';
          _this.form=res;
      });

    },
    //删除
    delClick: function (row) {
      var _this = this;
      var link = url.getApplyRecord("Delete_ArticleOther"); //获取请求地址
      var id = row.id; //获取对象id

      this.$confirm("确定要删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          $.post(link, { id: id }, (res) => {
            _this.btnLoading = false;
            if (res.status) {
              _this.msg = res;
              _this.getList();
            }
          });
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  mounted() {
    // 获取视频类型
    this.initData();
    //获取服务器列表
    this.getList();
  },
};
</script>

<style>
.query-form {
  margin-top: 20px;
  padding-top: 25px;
  background: #f2f2f2;
}
.el-form-item--small.el-form-item {
  margin-right: 0px;
}
.el-button--primary {
  margin-left: 10px;
}
</style>
